
<head>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
  />
</head>

<body class="body" style="background-color: rgb(255, 255, 255); background-image: url('https://i.imgur.com/VBfkKwh.png');">

<section style="width: 100%;margin-top: 5vh;"  class="animate__animated animate__fadeInDown" >








    <div class="col-lg-12 col-md-6 d-flex align-items-stretch" style="text-align: center;justify-content: center;">
      <img style="border-radius: 2vh;" width="400" height="250" [src]="this.equipo[0].ESCUDO" alt="">

    </div>







    <div class="col-lg-12 col-md-6 d-flex align-items-stretch" style="text-align: center;justify-content: center;">
      <label for="" style="font-weight: bolder;font-size: 3vh;text-align: center;justify-content: center;color: #ffffff;"> {{this.equipo[0].NOMBRE}}</label> <br> <br>

<br>


    </div>
    <div class="col-lg-12 col-md-6 d-flex align-items-stretch" style="text-align: center;justify-content: center;">
      <button (click)="anterior()" style="border-radius: 1vh;background-color: #055d6a;border: solid; border-color: #055d6a;color: #ffffff;margin-right: 1vh;" type="button" class="btn">Anterior</button>
      <button (click)="siguiente()" style="border-radius: 1vh;background-color: #055d6a;border: solid; border-color: #055d6a;color: #ffffff;margin-left: 1vh;" type="button" class="btn">Siguiente</button>

    </div>
<br>
    <div class="col-lg-12 col-md-6 d-flex align-items-stretch" style="text-align: center;justify-content: center;">
      <button (click)="reset() " style="border-radius: 1vh; background-color: #055d6a;border: solid; border-color: #055d6a;color: #ffffff;" type="button" class="btn">
        Restablecer Asistencia
       </button>
    </div>




</section>

  <section class="photos-section py-5 d-flex justify-content-center" id="seccion" style="margin-top: -5vh;" >












  <div class="row animate__animated animate__fadeInUp" style="width: 100%; justify-content: center; text-align: center;" >






    <div class="card mb-3" style="max-width: 540px;margin-left: 2vh;border-radius: 1vh;"  *ngFor="let datos of data ">
      <div class="row g-0">
        <div class="col-md-4">
          <img [src]="datos.FOTO" width="150" height="200" style="border-radius: 2vh;margin-top: 2vh;" >
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">{{datos.NOMBRE}}</h5>
            <p class="card-text"> Dorsal: {{datos.DORSAL}}</p>

            <img style="justify-content: end; text-align: end;border-radius: 2vh;" [src]="this.equipo[0].ESCUDO" width="100" height="80" >
            <br>
            <label for=""> Partidos Asistidos:</label> <code style="font-size: 2vh;">{{this.total}}</code>
            <br>
               <button (click)="listaAsistencia(datos.ID,datos.NOMBRE,datos.FOTO) " style="border-radius: 1vh;background-color: #055d6a;border: solid; border-color: #055d6a;color: #ffffff;" type="button" class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
               Asistencia
              </button>








          </div>
        </div>
      </div>
    </div>









  </div>




  </section>





   <!-- Modal -->
   <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel" style="text-align: center;color: #ef089a;"> {{this.nombre}}</h5>
          <br>
          <img [src]="this.foto" height="320" width="300" style="border-radius: 2vh;" alt="">
          <br>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="color: #055d6a;font-weight: bolder;">Partidos Asistidos: <code style="font-size: 2vh;">{{this.total}}</code>
        </div>
        <div class="modal-footer">
          <button type="button" style="background-color: #cf1228;border-color: #cf1228; color: #ffffff;height: 35px;width: 65px;border-radius: .5vh;"  data-bs-dismiss="modal">Cerrar</button>
          <button type="button"  style="background-color: #055d6a;border-color: #055d6a; color: #ffffff;height: 35px;width: 165px;border-radius: .5vh;"  (click)="this.cargar()">Registrar Asistencia</button>
        </div>
      </div>
    </div>
  </div>
