import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

declare var modal: any;


@Component({
  selector: 'app-lista-jugadoras',
  templateUrl: './lista-jugadoras.component.html',
  styleUrls: ['./lista-jugadoras.component.scss']
})
export class ListaJugadorasComponent {
  data: any
  nombreEqupo: any
  equipo: any
  num: any
  total:any
asist:any
asistencia:any
nombre:any
foto:any


id:any
fotoR:any
nombreR:any
  documentForm = new FormGroup({
    JUGADORA: new FormControl('', Validators.required),
    NOMBRE: new FormControl('', Validators.required),
    FOTO: new FormControl('', Validators.required),
  });

  constructor(private router: Router, private service: GeneralService, private activerouter: ActivatedRoute, private alerts: SweetAlertService) { }

  ngOnInit() {
    this.nombreEqupo = this.activerouter.snapshot.paramMap.get('id');
    this.service.listaJugadorasID(this.nombreEqupo).subscribe(res => {
      this.data = res.body;
      console.log(this.data);

    })



    this.service.listaEquipoID(this.nombreEqupo).subscribe(res => {
      this.equipo = res.body;
      console.log(this.equipo);

    })


  }

  siguiente(){
    this.nombreEqupo = this.activerouter.snapshot.paramMap.get('id');
this.nombreEqupo++

if(this.nombreEqupo<20){

  this.router.navigate(['admin/jugadoras/', this.nombreEqupo]).then(res=>{
    location.reload()
  })

}


  }


anterior(){
  this.nombreEqupo = this.activerouter.snapshot.paramMap.get('id');
  this.nombreEqupo--;
if(this.nombreEqupo>0){
 this.router.navigate(['admin/jugadoras/', this.nombreEqupo]).then(res=>{
    location.reload()
  })
}




}



  cargar() {

       this.documentForm.value.JUGADORA=this.id
       this.documentForm.value.NOMBRE=this.nombreR
       this.documentForm.value.FOTO=this.fotoR
        if (
          this.documentForm.value.JUGADORA !== '' ) {
          console.log(this.documentForm.value);


          this.alerts.alertaConfirmacion('Asistencia', '¿Desea hacer el registro?').then((res: any) => {

            if (res.isConfirmed) {



              this.service.asistencia(this.documentForm.value).subscribe(res => {

                this.service.asistenciaLista(this.documentForm.value.JUGADORA).subscribe(res => {
                  this.asistencia = res.body;
                  this.total=this.asistencia.length-1



                })

                this.alerts.alertaRealizadoAsistencia('Completado', 'Asistencia registrada');
              })


            }




          })


        }
      }



  verEquipo(id: any) {
    this.router.navigate(['admin/jugadoras/', id])
  }

listaAsistencia(id:any,nombre:any,foto:any){
  this.service.asistenciaLista(id).subscribe(res => {
    this.asistencia = res.body;
    this.total=this.asistencia.length - 1

this.fotoR=foto
this.nombreR=nombre
this.id=id
console.log(this.total);

this.foto=this.asistencia[0].FOTO
this.nombre=this.asistencia[0].NOMBRE

  })
}

reset(){

  this.alerts.alertaConfirmacion('Cuidado', 'Esta función elimina todas las asistencias ¿Desea realizar la acción?').then((res: any) => {

    if (res.isConfirmed) {



      this.service.resetAsisten().subscribe(res => {
  this.alerts.alertaRealizadoAsistencia('Completado', 'Asistencia reseteada');


      })



    }




  })





}
}
