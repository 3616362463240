import { Component } from '@angular/core';

import { GeneralService } from 'src/services/general.service';
import {  ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import * as XLSX from 'xlsx';
import Chart from 'chart.js/auto';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-clasificacion',
  templateUrl: './clasificacion.component.html',
  styleUrls: ['./clasificacion.component.scss']
})
export class ClasificacionComponent {
  name = 'Clasificaion-Liga de Voleibol.xlsx';
  data:any
  pages: number = 1;
  fuerza:any
  fuerza1:any
  constructor(private router: Router, private service: GeneralService) { }

  ngOnInit() {

    this.service.clasificacionfuerza(0).subscribe(res => {
      this.fuerza = res.body;
     console.log(this.fuerza);


    })

    this.service.clasificacionfuerza(1).subscribe(res => {
      this.fuerza1 = res.body;
      console.log(this.fuerza1);

    })



}

descargarDatos() {
  this.exportToExcel();
}

cambio() {
  this.pages = 1;
}



exportToExcel(): void {
  let element = document.getElementById('reporte');
  const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  const book: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(book, worksheet, 'Sheet1');

  XLSX.writeFile(book, this.name);
}

reporte() {
  this.exportToExcel();
}
}
