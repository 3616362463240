import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-partido',
  templateUrl: './partido.component.html',
  styleUrls: ['./partido.component.scss']
})
export class PartidoComponent {
data:any
documentoForm = new FormGroup({
  LOCAL: new FormControl(''),
  VISITANTE: new FormControl(''),
  PUNTOSL: new FormControl(''),
  PUNTOSV: new FormControl(''),
  SETP: new FormControl(''),
  GANADOR:new FormControl(''),

})


  constructor(private api: GeneralService, private alerts: SweetAlertService,  private router: Router) { }

  ngOnInit(): void {
    this.api.listaEquipos().subscribe(res => {
      this.data = res.body;
      console.log(this.data);

    })

    localStorage.setItem('marcador','0')
    localStorage.setItem('puntosV','0')
    localStorage.setItem('puntosL','0')
    localStorage.setItem('Set','1')
    localStorage.setItem('setL','0')
    localStorage.setItem('setV','0')
  }

crear(){




  localStorage.setItem('puntosV','0')
  localStorage.setItem('puntosL','0')
  localStorage.setItem('Set','1')

  this.documentoForm.value.PUNTOSL='0'
  this.documentoForm.value.PUNTOSV='0'
  this.documentoForm.value.SETP='0'
  this.documentoForm.value.GANADOR='En juego'


  if (this.documentoForm.value.LOCAL !== '' &&
    this.documentoForm.value.VISITANTE !== ''
) {
    console.log(this.documentoForm.value);
    this.api.nuevaPartido(this.documentoForm.value).subscribe(res => {
      const id = res.body.insertId;
      localStorage.setItem('idPartido',id)
      this.alerts.alertaRealizadoAsistencia('¡A JUGAR!', 'Partido listo');

this.router.navigate(['admin/index'])
    })
  } else {
    this.alerts.alertaError('Error', 'Llene los campos necesarios');
  }
}







}

