import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { timer } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from 'src/services/sweet-alert.service';

declare var modal: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  video: any
  token: any
  puntosL = 0;
  puntosV = 0;
  set = 1;
  puntosLform: any
  puntosVform: any
  setfomr: any
  id: any
  puntosMarL: any
  puntosMarV: any
  setMar: any
partido:any


marcador:any
idLocal:any
idVisita:any
puntosLocal:any
puntosVisitante:any
derrotasLocal:any
derrotasVisita:any

sumaPL:any
sumaPV:any
sumaDL:any
sumaDV:any
winsL:any
winsV:any

actPuntosL:any
actPuntosV:any
localset=0
visitanteset=0

mostrarSetL:any
mostrarSetV:any

timeLeft: number = 30;
interval:any
minutes: number = 0;

actPL: number=0
actVL: number=0
actPL2: number=0
actVL2: number=0
actPL3: any
actVL3:any

  documentoForm = new FormGroup({
    ID: new FormControl(''),
    LOCAL: new FormControl(''),
    VISITANTE: new FormControl(''),
    PUNTOSL: new FormControl(''),
    PUNTOSV: new FormControl(''),
    SETP: new FormControl(''),
    GANADOR: new FormControl(''),
  })
  puntosLf = new FormGroup({
    ID: new FormControl(''),
    PUNTOST:new FormControl(''),
  })

  puntosVf = new FormGroup({
    ID: new FormControl(''),
    PUNTOST:new FormControl(''),
  })


  localForm= new FormGroup({
    ID: new FormControl(''),
    PUNTOS: new FormControl(''),
    DERROTAS: new FormControl(''),
    VICTORIAS: new FormControl('')

  })

 visitanteForm= new FormGroup({
    ID: new FormControl(''),
    PUNTOS: new FormControl(''),
    DERROTAS: new FormControl(''),
    VICTORIAS: new FormControl(''),

  })

  name = "Angular";
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;

  videoItems = [
    {
      name: 'Perfumes Originales Ana Casillas',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR40995G1WhatsApp%20Video%202024-10-31%20at%2012.24.32%20PM.mp4?alt=media&token=e6ea2d28-b8a4-4eb5-9365-ee4cbbd600a2',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/5a7dRvH.png',
      number:0
    }, {
      name: 'CRMAH',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR32130G1video6.mp4?alt=media&token=28a8df78-cd7c-4fdf-9844-f173a5627620',
      type: 'video/mp4',
      Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31728G1-%20Autlan?alt=media&token=7b1ec603-c8e5-4eef-8040-e20bc4dc04f4',
      number:1
    },
    {
      name: 'Materiales Timo Castellón',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR32130G1video3.mp4?alt=media&token=be066b34-d8a3-4336-ad45-0853e55c4dc0',
      type: 'video/mp4',
      Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31729G1-%20Autlan?alt=media&token=87bf769e-a78a-44d5-8883-a8ec356e0aa8',
      number:2
    },
    {
      name: 'Horfi',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR36697G1video5.mp4?alt=media&token=e7a426d5-4124-4fa0-bbf2-bc4887bf648a',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/64IAN9T.png',
      number:3
    }
    ,
    {
      name: 'El Pueblito',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR36702G1El%20pueblito%20video.mp4?alt=media&token=9a5799d7-c602-47e3-9af7-b5cac5879014',
      type: 'video/mp4',
      Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31732G1-%20Autlan?alt=media&token=a6e7d57f-6631-4e8c-96d0-ec9961c7b8ca',
      number:4
    }

,{
  name: 'Los carrizos',
  src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR36697G1WhatsApp%20Video%202024-09-03%20at%2012.57.49%20PM.mp4?alt=media&token=a1c5f04c-0dfe-4739-b478-92050297fa46',
  type: 'video/mp4',
  Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31733G1-%20Autlan?alt=media&token=b3b10dc9-6f62-4643-9d39-ea73e9214bca',
  number:5
},

    {
      name: 'La muu',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR32130G1video.mp4?alt=media&token=e7982438-74d3-4283-8d9d-36f7524b0f05',
      type: 'video/mp4',
      Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31731G1-%20Autlan?alt=media&token=8062a11a-af27-4473-b75c-6e27b3ca9539',
      number:6
    }

, {
  name: 'Hamburguesas "La terminal"',
  src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR36697G1WhatsApp%20Video%202024-09-03%20at%2012.57.44%20PM%20(1).mp4?alt=media&token=85173870-b0d4-4039-ad1f-b853dc467597',
  type: 'video/mp4',
  Image: 'https://i.imgur.com/eHfrqp6.jpeg',
  number:7
}, {
  name: 'Corporativo Funerario',
  src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR36702G1video%201%20ocmpleto%20.mp4?alt=media&token=369f5c78-d471-4731-9b15-381b88a34ad4',
  type: 'video/mp4',
      Image: 'https://i.imgur.com/BsrcDR7.png',
      number:8
}, {
  name: 'El caldero',
  src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR36697G1WhatsApp%20Video%202024-09-03%20at%2012.57.50%20PM%20(1).mp4?alt=media&token=ed2af0f5-9b67-4885-8ee5-3f64243e12bcvideo%201%20ocmpleto%20.mp4:%20https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR36697G1video%201%20ocmpleto%20.mp4?alt=media&token=922bd759-6ee2-42c5-ba31-fc5593a7056b',
  type: 'video/mp4',
  Image: 'https://i.imgur.com/JBLeJtk.jpeg',
  number:9
},
{
  name: 'Deportes peña',
  src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR32130G1video2.mp4?alt=media&token=eb13ce8e-dbf8-46eb-8802-2616d286dc64',
  type: 'video/mp4',
  Image: 'https://i.imgur.com/3n26TVH.png',
  number:10
}
, {
  name: 'Radiology',
  src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR36697G1WhatsApp%20Video%202024-09-03%20at%2012.57.50%20PM.mp4?alt=media&token=d1c495f2-ac1d-4be2-9003-2774eea22185',
  type: 'video/mp4',
  Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31735G1-%20Autlan?alt=media&token=58996ad7-f3a4-46af-ab95-5fade0c44851',
  number:11
},
    {
      name: 'Traitte',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR36697G1WhatsApp%20Video%202024-09-03%20at%2012.57.13%20PM.mp4?alt=media&token=331f00ad-49b0-4011-b820-d94b42f9c571',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/T4Vkr4z.png',
      number:12
    },
    {
      name: 'Carretero To Go',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR32130G1video4.mp4?alt=media&token=4ef6979d-21ef-4064-af0a-50c9c61e67e2',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/5Z2VMB4.png',
      number:13
    }
    ,
    {
      name: 'Mundo del Niño',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR37018G1IMG_4133%20(1).mp4?alt=media&token=27636ef6-8691-4062-9444-8d5a50a8ecaf',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/kCJSsF8.jpeg',
      number:14
    }
    ,
    {
      name: 'Rancho el aguaje',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR37475G1PROMO%20VIDEO%20AGUAJE%201%20MIN.mp4?alt=media&token=3bb3a91f-a4cd-458b-94a4-eb6c11de1546',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/t9nGhA6.png',
      number:15
    } ,
    {
      name: 'Mind Water',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR38113G1WhatsApp%20Video%202024-09-23%20at%2011.46.01%20AM.mp4?alt=media&token=5d7d554d-6362-430e-a388-93e727a49368',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/GwFEF6n.png',
      number:16
    } ,
    {
      name: 'Favoritos',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR38113G1WhatsApp%20Video%202024-09-23%20at%2011.46.01%20AM%20(1).mp4?alt=media&token=05b5d18d-afe5-484d-a976-f9ffd713797b',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/BkG0DM4.png',
      number:17
    } ,
    {
      name: 'Favoritos',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR38113G1WhatsApp%20Video%202024-09-23%20at%2011.46.01%20AM%20(2).mp4?alt=media&token=7a90cc70-5ed6-4477-9371-8207972ded49',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/iO0kHxL.png',
      number:18
    }
    ,
    {
      name: 'Rinconcito',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR38703G1Rinconsito.mp4?alt=media&token=4919e3c1-f815-451a-b266-31f02b126bd2',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/ZFTPQkn.jpeg',
      number:19
    }   ,
    {
      name: 'Cortito',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR38872G1Corito-1%20(1).mp4?alt=media&token=80cbd74b-40bf-4a76-ad36-76e5415aa34a',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/urdfeoH.png',
      number:20
    } ,
    {
      name: 'Torneo Relampago ',
      src: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR40999G1IMG_4209.MP4?alt=media&token=f541d3aa-40cd-438e-82ba-6ac704bbd334',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/urdfeoH.png',
      number:21
    }


  ];
  activeIndex = 0;
  currentVideo = this.videoItems[this.activeIndex];
  data: any;
  datos: any
  partidoAct:any
  local:any
  visita:any
  constructor(private router: Router, private service: GeneralService, private alertas: SweetAlertService) { }

  ngOnInit(): void {
this.marcador=localStorage.getItem('marcador')
    localStorage.getItem('indice')
this.mostrarSetL=localStorage.getItem('setL')
this.mostrarSetV=localStorage.getItem('setV')


    this.puntosMarL = localStorage.getItem('puntosL')
    this.puntosMarV = localStorage.getItem('puntosV')
    this.setMar = localStorage.getItem('Set')

    this.puntosL= this.puntosMarL
    this.puntosV= this.puntosMarV
    this.setMar= this.setMar

    this.currentVideo = this.videoItems[this.activeIndex];
    this.oberserableTimer()

    if (localStorage.getItem('token')) {
      this.token = true;
    } else {
      this.token = false;

    }


    this.service.ultimoPartido().subscribe(res => {
      this.partido = res.body;



      this.service.listaEquipoID(this.partido[0].LOCAL).subscribe(res => {
        this.local = res.body;
        this.idLocal= this.local[0].ID
        this.puntosLocal=this.local[0].PUNTOS
        this.derrotasLocal=this.local[0].DERROTAS
        this.winsL=this.local[0].VICTORIAS

        this.localForm.value.PUNTOS=this.puntosLocal
        this.localForm.value.DERROTAS=this.derrotasLocal
        this.localForm.value.VICTORIAS=this.winsL



      })

      this.service.listaEquipoID(this.partido[0].VISITANTE).subscribe(res => {
        this.visita = res.body;
        this.idVisita=this.visita[0].ID
        this.puntosVisitante=this.visita[0].PUNTOS
        this.derrotasVisita=this.visita[0].DERROTAS
        this.winsV=this.visita[0].VICTORIAS



        this.visitanteForm.value.PUNTOS=this.puntosVisitante
        this.visitanteForm.value.DERROTAS=this.derrotasVisita
        this.visitanteForm.value.VICTORIAS=this.winsV


      })

    })

  }
listaRepro(number:any){
  this.activeIndex=number;

console.log(this.activeIndex);


  if (this.activeIndex === this.videoItems.length) {
    this.activeIndex = 0;
  }
  this.currentVideo = this.videoItems[this.activeIndex];

}

  startTimerL() {

    this.alertas.alertaDescanso('Tiempo de pausa solicitado',  this.local[0].NOMBRE)

  }


  startTimerV() {

    this.alertas.alertaDescanso('Tiempo de pausa solicitado',  this.visita[0].NOMBRE)

  }
  reproducir() {
    const audio = new Audio('/src/assets/img/alerta.mp3');
    audio.play();
  }

video1(){

  this.nextVideo()
}
  oberserableTimer() {
    let video: any = document.getElementById("my_video_1");


    const source = timer(100, 200);
    const abc = source.subscribe(val => {




      if (video.currentTime === video.duration) {
        this.nextVideo()



      }


    });
  }


actualizarPuntos(){

  this.service.ultimoPartido().subscribe(res => {
    this.partido = res.body;



    this.service.listaEquipoID(this.partido[0].LOCAL).subscribe(res => {
      this.local = res.body;
      this.idLocal= this.local[0].ID
      this.puntosLocal=this.local[0].PUNTOS
      this.derrotasLocal=this.local[0].DERROTAS
      this.winsL=this.local[0].VICTORIAS

      this.localForm.value.PUNTOS=this.puntosLocal
      this.localForm.value.DERROTAS=this.derrotasLocal
      this.localForm.value.VICTORIAS=this.winsL



    })

    this.service.listaEquipoID(this.partido[0].VISITANTE).subscribe(res => {
      this.visita = res.body;
      this.idVisita=this.visita[0].ID
      this.puntosVisitante=this.visita[0].PUNTOS
      this.derrotasVisita=this.visita[0].DERROTAS
      this.winsV=this.visita[0].VICTORIAS



      this.visitanteForm.value.PUNTOS=this.puntosVisitante
      this.visitanteForm.value.DERROTAS=this.derrotasVisita
      this.visitanteForm.value.VICTORIAS=this.winsV


    })

  })


this.actPL= this.puntosMarL
this.actPL2= this.local[0].PUNTOS
this.actPL3=   Number(this.actPL) + Number(this.actPL2)
console.log(this.actPL3);

  this.puntosLf.value.ID=this.local[0].ID
  this.puntosLf.value.PUNTOST=this.actPL3




  this.service.editarPuntos(this.puntosLf.value).subscribe(res => {


  })

  this.actVL= this.puntosMarV
  this.actVL2= this.visita[0].PUNTOS
  this.actVL3= Number(this.actVL) + Number(this.actVL2)
  console.log(this.actVL3);


  this.puntosVf.value.ID=this.visita[0].ID
  this.puntosVf.value.PUNTOST=this.actVL3





  this.service.editarPuntos(this.puntosVf.value).subscribe(res => {
   this.service.listaEquipoID(this.partido[0].VISITANTE).subscribe(res => {
    })


  })







}




  crear() {

    this.puntosLform = this.puntosL
    this.puntosVform = this.puntosV
    this.setfomr = this.set
    this.documentoForm.value.PUNTOSL = this.puntosLform
    this.documentoForm.value.PUNTOSV = this.puntosVform
    this.documentoForm.value.SETP = this.setfomr
    this.documentoForm.value.ID = this.id
    this.service.nuevaPartido(this.documentoForm).subscribe(res => {
      this.data = res.body;


    })
  }

  nextVideo() {
    this.activeIndex++;


    if (this.activeIndex === this.videoItems.length) {
      this.activeIndex = 0;
    }
    this.currentVideo = this.videoItems[this.activeIndex];


  }

regresarVideo(){
  this.activeIndex--;


  if (this.activeIndex === this.videoItems.length) {
    this.activeIndex = 0;
  }
  this.currentVideo = this.videoItems[this.activeIndex];

}


  initVdo() {
    this.data.play();
  }
  startPlaylistVdo(item: any, index: number) {
    this.activeIndex = index;
    this.currentVideo = item;
    this.initVdo()
  }









  sumarL() {
    this.puntosL++

    this.puntosLform = this.puntosL


    localStorage.setItem('puntosL', this.puntosLform)
this.puntosMarL=localStorage.getItem('puntosL')
  }

  restarL() {
    this.puntosL--

    this.puntosLform = this.puntosL
    localStorage.setItem('puntosL', this.puntosLform)
    this.puntosMarL=localStorage.getItem('puntosL')
  }

  sumarV() {
    this.puntosV++


    this.puntosVform = this.puntosV
    localStorage.setItem('puntosV', this.puntosVform)
    this.puntosMarV=localStorage.getItem('puntosV')

// this.alertas.alertaPunto(this.local[0].NOMBRE)

  }

  restarV() {
    this.puntosV--


    this.puntosVform = this.puntosV



    localStorage.setItem('puntosV', this.puntosVform)
    this.puntosMarV=localStorage.getItem('puntosV')
  }

  sumarS() {
    this.set++


    this.setfomr = this.set
    localStorage.setItem('Set', this.setfomr)
    this.setMar=localStorage.getItem('Set')
  }

  restarS() {
    this.set--

    this.setfomr = this.set
    localStorage.setItem('Set', this.setfomr)
    this.setMar=localStorage.getItem('Set')
  }


reiniciarM(){

this.alertas.alertaConfirmacion('¿Desea reiniciar el marcador?','').then(res=>{

  if(res.isConfirmed === true){


  this.puntosMarL=0
  this.puntosMarV=0
  this.setMar=1
this.mostrarSetL=0
this.mostrarSetV=0
  this.puntosV=0
  this.puntosL=0
  this.set=1



localStorage.setItem('puntosV','0')
localStorage.setItem('puntosL','0')
localStorage.setItem('Set','1')


localStorage.setItem('setL','0')
localStorage.setItem('setV','0')

  }})



}

reiniciarMSet(){



 localStorage.setItem('setL','0')
  localStorage.setItem('setV','0')



    this.puntosMarL=0
    this.puntosMarV=0
    this.setMar=1

    this.puntosV=0
    this.puntosL=0
    this.set=1


  localStorage.setItem('puntosV','0')
  localStorage.setItem('puntosL','0')
  localStorage.setItem('Set','1')



    }


rotarDatos(){
  this.marcador++
}


cambiarSet(){

  this.alertas.alertaConfirmacion('¿Siguiente set?','').then(res=>{

    if(res.isConfirmed === true){
      this.rotarDatos()
if(this.mostrarSetL<2 && this.mostrarSetV<2){
  if(this.puntosMarL>this.puntosMarV){


  this.localset++


  this.mostrarSetL=this.localset
  localStorage.setItem('setL', this.mostrarSetL)



  this.set++


  this.setfomr = this.set
  localStorage.setItem('Set', this.setfomr)
  this.setMar=localStorage.getItem('Set')


  this.puntosMarL=0
  this.puntosMarV=0


  this.puntosV=0
  this.puntosL=0


  localStorage.setItem('puntosV','0')
  localStorage.setItem('puntosL','0')

if(this.mostrarSetL==2){
  this.terminarSets()
  this.marcador=0
}




  }else if(this.puntosMarL<this.puntosMarV){
    this.visitanteset++

    this.mostrarSetV=this.visitanteset
    localStorage.setItem('setV', this.mostrarSetV)




    this.set++


    this.setfomr = this.set
    localStorage.setItem('Set', this.setfomr)
    this.setMar=localStorage.getItem('Set')


    this.puntosMarL=0
    this.puntosMarV=0


    this.puntosV=0
    this.puntosL=0


  localStorage.setItem('puntosV','0')
  localStorage.setItem('puntosL','0')


  if(this.mostrarSetV==2){
    this.terminarSets()
    this.marcador=0
  }
  }




}else{
  this.terminarSets()
}


    }


  })
}




  puntuacion() {
console.log('entra');


    if (this.mostrarSetL == 2 && this.mostrarSetV == 0) {
      this.sumaPL = this.puntosLocal + 3


      this.sumaDV = this.derrotasVisita + 1

this.sumaDL=this.derrotasLocal
this.sumaPV=this.puntosVisitante

this.localForm.value.ID=this.idLocal
this.localForm.value.DERROTAS=this.sumaDL
this.localForm.value.PUNTOS=this.sumaPL
this.localForm.value.VICTORIAS=this.winsL+1
      this.service.editarPuntos(this.localForm.value).subscribe(data => {

console.log('gana local 2-0');

        this.mostrarSetL = 0
        this.mostrarSetV = 0

      })
      this.visitanteForm.value.ID = this.idVisita
      this.visitanteForm.value.DERROTAS=this.sumaDV
      this.visitanteForm.value.PUNTOS=this.sumaPV
      this.visitanteForm.value.VICTORIAS=this.winsV
      this.service.editarPuntos(this.visitanteForm.value).subscribe(data => {


console.log('pierde visita 2-0');
        this.mostrarSetL = 0
        this.mostrarSetV = 0
      })

    }

    if (this.mostrarSetL == 2 && this.mostrarSetV == 1) {
      this.sumaPL = this.puntosLocal + 2
      this.sumaPV = this.puntosVisitante + 1




      this.sumaDV = this.derrotasVisita + 1
      this.localForm.value.ID=this.idLocal
      this.localForm.value.DERROTAS=this.sumaDL
      this.localForm.value.PUNTOS=this.sumaPL
      this.localForm.value.VICTORIAS=this.winsL+1
      this.service.editarPuntos(this.localForm.value).subscribe(data => {

        this.mostrarSetL = 0
        this.mostrarSetV = 0

console.log('gana local 2-1');
      })


      this.visitanteForm.value.ID = this.idVisita
      this.visitanteForm.value.DERROTAS=this.sumaDV
      this.visitanteForm.value.PUNTOS=this.sumaPV
      this.visitanteForm.value.VICTORIAS=this.winsV
      this.service.editarPuntos(this.visitanteForm.value).subscribe(data => {

        console.log('pierde visita 2-1');
        this.mostrarSetL = 0
        this.mostrarSetV = 0
      })

    }

    if (this.mostrarSetV == 2 && this.mostrarSetL == 0) {
      this.sumaPV = this.puntosVisitante + 3


      this.sumaDL = this.derrotasLocal + 1


      this.localForm.value.ID=this.idLocal
      this.localForm.value.DERROTAS=this.sumaDL
      this.localForm.value.PUNTOS=this.sumaPL
      this.localForm.value.VICTORIAS=this.winsL
      this.service.editarPuntos(this.localForm.value).subscribe(data => {


        console.log('gana visita 2-0');
        this.mostrarSetL = 0
        this.mostrarSetV = 0

      })
      this.visitanteForm.value.ID = this.idVisita
      this.visitanteForm.value.DERROTAS=this.sumaDV
      this.visitanteForm.value.PUNTOS=this.sumaPV
      this.visitanteForm.value.VICTORIAS=this.winsV+1
      this.service.editarPuntos(this.visitanteForm.value).subscribe(data => {


        console.log('pierde local 2-0');
        this.mostrarSetL = 0
        this.mostrarSetV = 0
      })

    }

    if (this.mostrarSetV == 2 && this.mostrarSetL == 1) {
      this.sumaPV = this.puntosVisitante + 2
      this.sumaPL = this.puntosLocal + 1
      this.sumaDL = this.derrotasLocal + 1

      this.localForm.value.ID=this.idLocal
      this.localForm.value.DERROTAS=this.sumaDL
      this.localForm.value.PUNTOS=this.sumaPL
      this.localForm.value.VICTORIAS=this.winsL
      this.service.editarPuntos(this.localForm.value).subscribe(data => {

        console.log('pierde local 2-1');

        this.mostrarSetL = 0
        this.mostrarSetV = 0

      })
      this.visitanteForm.value.ID = this.idVisita
      this.visitanteForm.value.DERROTAS=this.sumaDV
      this.visitanteForm.value.PUNTOS=this.sumaPV
      this.visitanteForm.value.VICTORIAS=this.winsV+1
      this.service.editarPuntos(this.visitanteForm.value).subscribe(data => {
        console.log('gana visita 2-1');

        this.mostrarSetL = 0
        this.mostrarSetV = 0
      })

    }


  }




  terminarPA(){





        if(this.puntosMarL>this.puntosMarV){
          this.documentoForm.value.GANADOR= this.local[0].ID
          this.alertas.alertaCelebracion( this.local[0].NOMBRE + ' ha ganado el partido!')
          this.reiniciarMSet()

          this.marcador=0
        this.puntosMarL=0
        this.puntosMarV=0
        this.setMar=1

        this.puntosV=0
        this.puntosL=0
        this.set=1


        localStorage.setItem('puntosV','0')
        localStorage.setItem('puntosL','0')
        localStorage.setItem('Set','1')
          this.router.navigate(['/admin/partido'])

        }else if(this.puntosMarV>this.puntosMarL){
          this.documentoForm.value.GANADOR= this.visita[0].ID
          this.alertas.alertaCelebracion(this.visita[0].NOMBRE + ' ha ganado el partido!')
          this.reiniciarMSet()


        this.puntosMarL=0
        this.puntosMarV=0
        this.setMar=1

        this.puntosV=0
        this.puntosL=0
        this.set=1

        this.marcador=0
        localStorage.setItem('puntosV','0')
        localStorage.setItem('puntosL','0')
        localStorage.setItem('Set','1')
  this.router.navigate(['/admin/partido'])

        }


        this.documentoForm.value.ID=localStorage.getItem('idPartido')
        this.documentoForm.value.LOCAL=this.local[0].ID
        this.documentoForm.value.VISITANTE=this.visita[0].ID
        this.documentoForm.value.PUNTOSL= this.puntosMarL
        this.documentoForm.value.PUNTOSV= this.puntosMarV
        this.documentoForm.value.SETP= this.setMar

        this.service.actualizarPartido(this.documentoForm.value).subscribe(res => {
          this.partidoAct = res.body;


        })








    }




terminarP(){


  this.alertas.alertaConfirmacion('¿Desea terminar el partido?','').then(res=>{

    if(res.isConfirmed === true){

      if(this.puntosMarL>this.puntosMarV){
        this.documentoForm.value.GANADOR= this.local[0].ID
        this.alertas.alertaCelebracion( this.local[0].NOMBRE + ' ha ganado el partido!')
        this.router.navigate(['/admin/partido'])

      }else if(this.puntosMarV>this.puntosMarL){
        this.documentoForm.value.GANADOR= this.visita[0].ID
        this.alertas.alertaCelebracion(this.visita[0].NOMBRE + ' ha ganado el partido!')
this.router.navigate(['/admin/partido'])

      }

      this.puntosMarL=0
      this.puntosMarV=0
      this.setMar=1

      this.puntosV=0
      this.puntosL=0
      this.set=1


      localStorage.setItem('puntosV','0')
      localStorage.setItem('puntosL','0')
      localStorage.setItem('set','1')

      this.documentoForm.value.ID=localStorage.getItem('idPartido')
      this.documentoForm.value.LOCAL=this.local[0].ID
      this.documentoForm.value.VISITANTE=this.visita[0].ID
      this.documentoForm.value.PUNTOSL= this.puntosMarL
      this.documentoForm.value.PUNTOSV= this.puntosMarV
      this.documentoForm.value.SETP= this.setMar

      this.service.actualizarPartido(this.documentoForm.value).subscribe(res => {
        this.partidoAct = res.body;


      })






    }})

  }



  terminarSets(){






        if(this.mostrarSetL>this.mostrarSetV){
          this.documentoForm.value.GANADOR= this.local[0].ID
          this.alertas.alertaCelebracion( this.local[0].NOMBRE + ' ha ganado el partido!')
   this.puntosMarL=0
          this.puntosMarV=0
          this.setMar=1
          this.mostrarSetL=0
          this.mostrarSetV=0
          this.puntosV=0
          this.puntosL=0
          this.set=1
          this.marcador=0

          localStorage.setItem('puntosV','0')
          localStorage.setItem('puntosL','0')

          localStorage.setItem('Set','1')
          this.reiniciarMSet()
          this.puntuacion()
          this.terminarPA()
          this.router.navigate(['/admin/partido'])

        }else if(this.mostrarSetV>this.mostrarSetL){
          this.documentoForm.value.GANADOR= this.visita[0].ID
          this.alertas.alertaCelebracion(this.visita[0].NOMBRE + ' ha ganado el partido!')
          this.reiniciarMSet()
          this.puntuacion()
          this.terminarPA()

          this.puntosMarL=0
          this.puntosMarV=0
          this.setMar=1
          this.mostrarSetL=0
          this.mostrarSetV=0
          this.puntosV=0
          this.puntosL=0
          this.set=1
          this.marcador=0

          localStorage.setItem('puntosV','0')
          localStorage.setItem('puntosL','0')

          localStorage.setItem('Set','1')
  this.router.navigate(['/admin/partido'])

        }

        this.puntosMarL=0
        this.puntosMarV=0
        this.setMar=1

        this.puntosV=0
        this.puntosL=0
        this.set=1


        localStorage.setItem('puntosV','0')
        localStorage.setItem('puntosL','0')

        localStorage.setItem('Set','1')
        this.documentoForm.value.ID=localStorage.getItem('idPartido')
        this.documentoForm.value.LOCAL=this.local[0].ID
        this.documentoForm.value.VISITANTE=this.visita[0].ID
        this.documentoForm.value.PUNTOSL= this.puntosMarL
        this.documentoForm.value.PUNTOSV= this.puntosMarV
        this.documentoForm.value.SETP= this.setMar







      }



}
