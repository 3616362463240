import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-nuevo-equipo',
  templateUrl: './nuevo-equipo.component.html',
  styleUrls: ['./nuevo-equipo.component.scss']
})
export class NuevoEquipoComponent {
  dato:any
  documentoForm = new FormGroup({
    NOMBRE: new FormControl(''),
    ESCUDO: new FormControl(''),
    FUERZA: new FormControl(''),
  })

  //Variables para url de archivos
  urlDocumento = '';

  //Variables para indicar carga de archivos
  subiendoDocumento = false;

  docs: any;
  fecha: any;
  fdia: any;
  fmes: any;
  fanio: any;
  idNoticia: any;

  constructor(private api: GeneralService, private alerts: SweetAlertService, private storage: Storage, private router: Router) { }

  ngOnInit(): void {

  }



  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.api.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
    })
  })


  async changeFileMenu(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento = false;
          console.log(file);
this.dato=file;
          this.documentoForm.value.ESCUDO = file;
          console.log(this.documentoForm.value.ESCUDO);

        }).catch(error => { console.log(error) });

      })
    })

  }



  cargar() {


    console.log(this.documentoForm.value);
    if (this.documentoForm.value.ESCUDO !== '' &&
      this.documentoForm.value.NOMBRE !== ''
   ) {
      console.log(this.documentoForm.value);
      this.api.nuevaEquipo(this.documentoForm.value).subscribe(res => {

        this.alerts.alertaRealizadoAsistencia('Completado', 'La jugadora ha sido registrada');
location.reload()
      })
    } else {
      this.alerts.alertaError('Error', 'Llene los campos necesarios');
    }
  }

}
